import React from 'react'

export const InputField = (props) => {
  const { type, className, placeholder, value, setValue, ...rest } = props




  return (
    <input type={type} className={` input-field ` + className } placeholder={placeholder}
      value={value} onChange={(e) => setValue(e.target.value)}  {...rest} /> 

      
  )
}
