import React from 'react'
import { ButtonWig } from './ButtonWig'
import { useNavigate } from 'react-router-dom'
import { RoutesName } from "../utils/RoutesName";
// import { logout } from '../service/firebase-service'
import { useDispatch } from 'react-redux';
import { logoutState } from '../slices/AuthSlice';

export const LogoutModal = ({setShowLogout}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div className="settings-modal" style={{'minHeight':'196px'}}>
    <div className="settings-modal-content text-center" style={{'padding':''}}>
        <h4 className='fw-bold logout-modal-heading'>Log Out</h4>
        <p className='thin-text  p-0  text-light fs-6' style={{'marginTop':'20px','marginBottom':'20px'}}>Do you really want to log out?</p>
        <div className='d-flex'>
        <button className={` ${' main-button'}  fw-bold text-center text-white me-2  fw-bold`} style={{'padding':'6px auto','height':'35px','fontSize':'16px'}} type={'button'} onClick={()=>{
          dispatch(logoutState())
          navigate(RoutesName.index)
          
          }} >
        
        Log Out
      </button>
        <button className={` ${' main-button'} bg-secondary fw-bold text-center text-white me-2  fw-bold`} style={{'padding':'6px auto','height':'35px','fontSize':'16px'}} type={'button'} onClick={()=>{setShowLogout(false)}} >
        
        Cancel
      </button>
        {/* <ButtonWig text={'Cancel'} className={' text-white bg-secondary ms-2 fs-5 fw-bold'} onClick={()=>{setShowLogout(false)}}/> */}
        </div>

    </div>
  </div>
  )
}
