import React, { useEffect, useState } from 'react';
import './chatscreen.css';
import RefreshIcon from '../../assets/refresh-icon.svg'
import SettingsIcon from '../../assets/settings-icon.svg'
import BackIcon from '../../assets/back.png'
import { Divider } from '../../widgets/Divider';
import { SettingsModal } from '../../widgets/SettingsModal';
import { LogoutModal } from '../../widgets/LogoutModal';
import ChatScreen from '../ChatScreen/ChatScreen';
import { ManageSubscription } from '../ManageSubscription/ManageSubscription';
import { useNavigate } from 'react-router-dom';
import { SuccessToast, SuccessToastBottom } from '../../utils/Toast-Wig';
import { createGlobalStyle } from 'styled-components';
import { RoutesName } from '../../utils/RoutesName';

function ChatLayout({isTry}) {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showSettings, setShowSettings] = useState(false)
  const [showLogout, setShowLogout] = useState(false)
  const [manageSub, setManageSub] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState(localStorage.getItem('chat')!== null? JSON.parse(localStorage.getItem('chat')):[])
  const navigate = useNavigate();

  const handleBackButton = () => {
    // Run your function for back button event

    console.log('User clicked back button');
    setManageSub(false)
    navigate('/chat');
    // Prevent default behavior of navigating to previous page
  };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   window.addEventListener('keyboardDidShow', handleKeyboardShow);
  //   window.addEventListener('keyboardDidHide', handleKeyboardHide);
  //   window.addEventListener('popstate', handleBackButton);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     window.removeEventListener('keyboardDidShow', handleKeyboardShow);
  //     window.removeEventListener('keyboardDidHide', handleKeyboardHide);
  //     window.removeEventListener('popstate', handleBackButton);

  //   };
  // }, []);

  useEffect(() => {
    setHeaderHeight(document.querySelector('.chat-screen-header').clientHeight);
  }, []);

  // const handleResize = () => {
  //   if (isKeyboardOpen) {
  //     setKeyboardHeight(window.innerHeight - document.activeElement.getBoundingClientRect().bottom);
  //   }
  // };
  const showManageSub = () => {
    setConversation([])
    setManageSub(true);
  }
  const handleKeyboardShow = () => {
    setIsKeyboardOpen(true);
    setKeyboardHeight(window.innerHeight - document.activeElement.getBoundingClientRect().bottom);
  };
  
  const handleKeyboardHide = () => {
    setIsKeyboardOpen(false);
    setKeyboardHeight(0);
  };
  function handleRefresh() {
    localStorage.removeItem('chat')
    setInputValue('')
    setConversation([])
  }

  const GlobalStyle = createGlobalStyle`
  body, html, :root {
    background-color: #000000;
  }
`;



  return (
    <>
    <GlobalStyle />
        <header className="chat-screen-header " >
          <div className='p-3 d-flex justify-content-between align-items-center'>
            {/* {
              manageSub ?
                <img className='image-button' src={BackIcon} alt="back icon" onClick={() => setManageSub(false)} />
                : */}
                <img className='image-button' src={RefreshIcon} alt="refresh" onClick={() => handleRefresh()} />

            {/* } */}
            {
              !isTry?
              <>
              <h4 className='fw-bold'><span className='text-green'>Ask</span>ME</h4>
              <img className='image-button' src={SettingsIcon} alt="settings" onClick={() => setShowSettings(true)} />
              </>
              :
              <button className='text-white main-button-small fw-bold text-center p-2' onClick={() => navigate(RoutesName.register+'#footer')}>
                Get AskMe Now!
              </button>
            }
          </div>
          <Divider className={'w-100 m-0'} />
        </header>
      <div className="chat-screen-container bg-danger" style={{'overflow':'hidden','flex':'1'}} onClick={() => { if (showSettings) { setShowSettings(false) } }}>
        {/* {
          manageSub ?
            <ManageSubscription /> : */}
            <ChatScreen inputValue={inputValue} setInputValue={setInputValue} conversation={conversation} setConversation={setConversation}  isTry={isTry}/>

        {/* } */}


      </div>
      {
        showSettings ?
          <SettingsModal setShowLogout={setShowLogout} setShowSettings={setShowSettings} showManageSub={showManageSub} />
          : showLogout ?
            <LogoutModal setShowLogout={setShowLogout} /> : null
      }

    </>
  );
}

export default ChatLayout;
