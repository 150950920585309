import { useState, useEffect } from 'react';

function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      setShowNotification(true);
      setTimeout(() => {
        setIsOnline(false);
        setShowNotification(false);
      }, 3000);
    }

    function handleOffline() {
      setIsOnline(false);
      setShowNotification(true);
      
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline, showNotification };
}

export default useOnlineStatus;
