export const RoutesName = {
  index: "/",
  login: "/otp",
  loginWithEmail: "/login",
  signup: "/signup",
  register: "/paymentplan",
  onBoarding: "/onboarding",
  onBoardingTwo: "/onboardingTwo",
  onboardingThree: "/onboardingThree",
  subscriptions: "/subscriptions",
  chat: "/chat",
  manage: "/managesubscription",
  manageSubscriptions: "/chat/manage-subscription",
  resetPassword: "/reset-password",
  terms: "/termsofservice",
  privacy: "/privacypolicy",
};
