import React, { useEffect, useState } from 'react'
import Bot from '../assets/bot.png'
import Copy from '../assets/copy.png'
import { SuccessToast } from '../utils/Toast-Wig';
import CopyToClipboard from 'react-copy-to-clipboard';
import Clipboard from 'react-clipboard-animation'
export const LeftMessage = ({ text, scrollBottom, className ,refLoad}) => {
  const [i, setI] = useState(0);



  useEffect(() => {

    setTimeout(() => {
      setI(1)

    }, 0);

    setTimeout(() => {
      scrollBottom()
      
    }, 200);

    
  }, [])

  const [copied, setCopied] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className={`d-flex flex-column ${className}`}>
      <div className="left-message  d-flex justify-content-between align-items-center">
        {
          animate ?
            <div class="middle-div">

              <div class="overlay-div">
                <span class="text">Copied</span>
              </div>
            </div> : null
        }

        <p className="p-0 m-0 message" style={{ opacity: i }} >
          {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <CopyToClipboard text={text} >
          {/* <button className=""> */}

          <img src={Copy} className='ms-3 image-button' onClick={() => {
            setAnimate(true)
            setTimeout(() => {
              setAnimate(false)
            }, 1000);
          }} width={18} height={18} alt="" />
          {/* </button> */}
          {/* <button
        onClick={() => setCopied(true)}
        style={{
          appearance: "none",
          backgroundColor:'transparent',
          padding: 8,
          border: 0,
          outline: 0,
          cursor: "pointer"
        }}
      >
        <div
          style={{
            position: "relative",
            height: 18,
            width: 18
          }}
        >
          <Clippy
            style={{
              color: 'white',
              position: "absolute",
              top: 0,
              left: 0,
              strokeDasharray: 50,
              strokeDashoffset: copied ? -50 : 0,
              transition: "all 1s ease-in-out"
            }}
          />
          <Check
            isVisible={copied}
            style={{
              color: '#10A37F',
              position: "absolute",
              top: 0,
              left: 0,
              strokeDasharray: 50,
              strokeDashoffset: copied ? 0 : -50,
              transition: "all 2s ease-in-out"
            }}
          />
        </div>
      </button> */}
        </CopyToClipboard>

      </div>
      <img src={Bot} alt="" width={22} height={17} />
    </div>
  )
}
function Clippy(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
      <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
    </svg>
  );
}

function Check(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}