import React, { useEffect, useState } from 'react';
import './chatscreen.css';
import RefreshIcon from '../../assets/refresh-icon.svg'
import SettingsIcon from '../../assets/settings-icon.svg'
import BackIcon from '../../assets/back.png'
import { Divider } from '../../widgets/Divider';
import { SettingsModal } from '../../widgets/SettingsModal';
import { LogoutModal } from '../../widgets/LogoutModal';
import ChatScreen from '../ChatScreen/ChatScreen';
import { ManageSubscription } from '../ManageSubscription/ManageSubscription';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../widgets/LinkButton';
import { RoutesName } from '../../utils/RoutesName';
import { Footer } from '../Footer/Footer';

function LayoutManageSub(props) {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showSettings, setShowSettings] = useState(false)
  const [showLogout, setShowLogout] = useState(false)
  const [manageSub, setManageSub] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState([])
  const navigate = useNavigate();

  const handleBackButton = () => {
    // Run your function for back button event
    console.log('User clicked back button');
    setManageSub(false)
    navigate('/chat');
    // Prevent default behavior of navigating to previous page
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('keyboardDidShow', handleKeyboardShow);
    window.addEventListener('keyboardDidHide', handleKeyboardHide);
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyboardDidShow', handleKeyboardShow);
      window.removeEventListener('keyboardDidHide', handleKeyboardHide);
      window.removeEventListener('popstate', handleBackButton);

    };
  }, []);

  useEffect(() => {
    setHeaderHeight(document.querySelector('.chat-screen-header').clientHeight);
  }, []);

  const handleResize = () => {
    if (isKeyboardOpen) {
      setKeyboardHeight(window.innerHeight - document.activeElement.getBoundingClientRect().bottom);
    }
  };
  const showManageSub = () => {
    setConversation([])
    setManageSub(true);
  }
  const handleKeyboardShow = () => {
    setIsKeyboardOpen(true);
    setKeyboardHeight(window.innerHeight - document.activeElement.getBoundingClientRect().bottom);
  };

  const handleKeyboardHide = () => {
    setIsKeyboardOpen(false);
    setKeyboardHeight(0);
  };
  function handleRefresh() {
    setInputValue('')
    setConversation([])
  }





  return (
    <>
      <div className="chat-screen-container bg-main" style={{'minHeight':'100dvh'}} onClick={() => { if (showSettings) { setShowSettings(false) } }}>
        <header className="chat-screen-header " style={{ top: `${keyboardHeight}px` }}>

          <div className='p-3 d-flex justify-content-between align-items-center'>

            <img className='image-button' src={BackIcon} alt="back icon" onClick={() => navigate(-1)} />

            <h4 className='fw-bold'><span className='text-green'>Ask</span>ME</h4>
            <img className='image-button' src={SettingsIcon} alt="settings" onClick={() => setShowSettings(true)} />
          </div>
          <Divider className={'w-100 m-0'} />
        </header>

        <ManageSubscription />
        <div className='text-center' style={{ 'marginTop': 'auto',marginBottom:'auto' }}>

          <p className='mb-1 thin-text'>Do you want to cancel?</p>
          <p className='thin-text'>Reach our friendly support team <span className='text-decoration-underline text-green' onClick={(e) => {
            window.location.href = 'mailto:support@ask-me.app?subject=Cancel%20Subscription';
          }}>here</span></p>
        </div>
          <div className='text-center'>

          <Footer/>
          </div>
        {/* <div className=" d-block text-center border-0 bg-black" >
          <Divider className='mb-5 ' />
          <LinkButton text={"Terms and Conditions"} onClick={() => navigate(RoutesName.terms)} className="lh-base text-light mt-3" />
          <LinkButton text={"Privacy Policy"} onClick={() => navigate(RoutesName.privacy)} className="lh-base text-light mt-2" />
          <p className='light-text thin-text mt-4'>
            Copyright ® 2023 AskME. <br />All Rights Reserved.
          </p>
        </div> */}

      </div>
      {
        showSettings ?
          <SettingsModal setShowLogout={setShowLogout} setShowSettings={setShowSettings} showManageSub={showManageSub} />
          : showLogout ?
            <LogoutModal setShowLogout={setShowLogout} /> : null
      }

    </>
  );
}

export default LayoutManageSub;
