import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { RoutesName } from "../../utils/RoutesName";
import { ErrorToastBottom, SuccessToastBottom } from "../../utils/Toast-Wig";
import { ButtonWig } from "../../widgets/ButtonWig";

import axios from "axios";
import { useDispatch } from "react-redux";
import {
  create_hash,
  generateRandomCode,
} from "../../service/firebase-service";
import { checkLogin } from "../../slices/AuthSlice";
import { BASE_URL } from "../../utils/constants";
import { Loader } from "../../widgets/Loader";
import { Footer } from "../Footer/Footer";

export const OTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isEmailSent, setIsEmailSent] = useState(true);
  useEffect(() => {
    if (
      localStorage.getItem("data") !== null &&
      localStorage.getItem("verification") !== null
    ) {
      setData(JSON.parse(localStorage.getItem("data")));
      setIsEmailSent(true);
    } else {
      setIsEmailSent(false);
      // navigate(RoutesName.index)
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    if (isEmailSent) {
      if (otp === data?.code) {
        // SuccessToastBottom('Email verified successfully.',3000)
        const hash_val = create_hash(data?.email.toLowerCase());
        localStorage.setItem("session", hash_val);
        dispatch(checkLogin());
        setTimeout(() => {
          navigate(RoutesName.chat);
        }, 1000);
      } else {
        ErrorToastBottom("Invalid Code", 3000);
      }
    } else {
      ErrorToastBottom("Email may not be null", 2000);
    }
    setLoading(false);
  };
  async function resend() {
    setOtp("");
    setLoading(true);
    const data = JSON.parse(localStorage.getItem("data"));
    const email = data.email.toLowerCase();

    if (email.includes("@") && email.includes(".")) {
      const code = generateRandomCode();
      const combined = email + code;
      const hash_val = create_hash(combined);
      localStorage.setItem(
        "data",
        JSON.stringify({ email: email, code: code })
      );
      localStorage.setItem("verification", hash_val);
      try {
        const res = await axios.post(
          BASE_URL + "/send-mail",
          {
            email: email,
            code: code,
          },
          {
            headers: {
              "xxx-header": `${hash_val}`,
            },
          }
        );
        // console.log(res)
        if (res.data.success && res.data?.data?.state === "active") {
          // navigate(RoutesName.login)
          SuccessToastBottom("Verification code sent", 3000);
        }
      } catch (e) {
        console.log(e);
        ErrorToastBottom("Something went wrong", 3000);
      }
    }
    setLoading(false);
  }

  return (
    <div className="video-background">
      <div className="main-bg">
        <div className="d-flex flex-column align-items-center">
          <img
            className="logo-main"
            src={Logo}
            alt="Logo"
            onClick={() => navigate(RoutesName.index)}
          />
          <h3 className="mt-3 main-heading fw-bold">Enter OTP</h3>

          <h6
            style={{ fontSize: "14px" }}
            className="text-center fw-light mb-4"
          >
            A one-time password has been sent to your <br />
            Email: {data?.email}
          </h6>
          <OtpInput
            inputStyle={{
              width: "100%",
              height: "68px",
              textAlign: "center",
              borderRadius: "6px",
              boxShadow: "none",
              color: "white",
              backgroundColor: "transparent",
              borderColor: "#707070",
              border: "1px solid #6A6C84",
              margin: "0px 5px",
            }}
            placeholder={0}
            inputType="tel"
            value={otp}
            renderSeparator={""}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
          {loading ? (
            <Loader className={"mt-3"} />
          ) : (
            <ButtonWig
              text={"Submit"}
              className="text-white mt-4 w-100 continue-btn"
              onClick={handleSubmit}
              type="submit"
              disabled={otp.length !== 4}
              showArrow={true}
            />
          )}

          <h6
            onClick={() => resend()}
            className="text-decoration-underline text-green mt-4 mb-5"
            style={{ fontSize: "14px" }}
          >
            Resend OTP
          </h6>
        </div>
        <div className="footer-bottom-container">
          <Footer />
        </div>
      </div>
    </div>
  );
};
