import React from "react";
import { useNavigate } from "react-router-dom";
import listBg from "../../assets/box-bg.svg";
import circleBg from "../../assets/circle-bg.png";
import { RoutesName } from "../../utils/RoutesName";
import { ButtonWig } from "../../widgets/ButtonWig";

export const Home = () => {
  const navigate = useNavigate();

  const features = [
    { id: 1, label: "Answers to All Questions" },
    { id: 2, label: "Complete Chat Memory" },
    { id: 3, label: "Fastest GPT Model" },
    { id: 4, label: "Most Detailed Responses" },
    { id: 5, label: "ChatGPT & ChatGPT-4" },
  ];

  return (
    <div className="main-bg">
      <p
        className="login-txt mb-2"
        onClick={() => navigate(RoutesName.loginWithEmail)}
      >
        Log In
      </p>
      <div style={{ position: "relative" }}>
        <img alt="" className="bg-circle" src={circleBg} />
        <div className="mt-3 mb-3">
          {features.map((data) => (
            <div key={data.id} className="list-container mt-4">
              <img alt="" className="w-100" src={listBg} />
              <p className="p-0 m-0 centered">{data.label}</p>
            </div>
          ))}
        </div>
      </div>
      <p className="personal-ai mt-3 p-0 m-0 mb-1" style={{ zIndex: 1 }}>
        Your Personal
        <br /> <span>AI Chatbot & Assistant</span>
      </p>
      <ButtonWig
        className="mt-4 text-white fw-bold continue-btn"
        text={"Continue"}
        onClick={() => navigate(RoutesName.onBoardingTwo)}
      />
      <p className="m-0 p-0 mt-4 accept-txt">
        By proceeding, you accept our
        <br />{" "}
        <span onClick={() => navigate(RoutesName.terms)}>
          Terms of Use
        </span> and{" "}
        <span onClick={() => navigate(RoutesName.privacy)}>Privacy Policy</span>
      </p>
    </div>
  );
};
