import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithPopup,
  sendEmailVerification

} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
  getDoc
} from "firebase/firestore";
import { SHA256 } from 'crypto-js';
import { ErrorToast } from "../utils/Toast-Wig";

const firebaseConfig = {
  apiKey: "AIzaSyBhMpE8hYlFTBOF2uUltpdVYWp4sTjSH58",
  authDomain: "ask-gpt-e240c.firebaseapp.com",
  projectId: "ask-gpt-e240c",
  storageBucket: "ask-gpt-e240c.appspot.com",
  messagingSenderId: "377153370736",
  appId: "1:377153370736:web:282ea4ad9a10eaa50312b5",
  measurementId: "G-3ESTCDSCNC"
};
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const db = getFirestore(app);
// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
//   try {
//     // await signInWithRedirect(auth, googleProvider);
//     // const result = await getRedirectResult(auth);
//     const result = await signInWithPopup(auth, googleProvider);
//     const user = result.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       const docRef = doc(db, "users", user.uid);

//       await setDoc(docRef, {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//         image: user.photoURL,
//         accountId: '',
//         subscribed: false
//       });
//       if (localStorage.getItem('data') === null) {
//         const userData = await getFirebaseUser(user.uid)
//         console.log({ 'status': true, 'message': 'Successfully registered', 'user': userData })
//         return { 'status': true, 'message': 'Successfully registered', 'user': userData }
//       }
//       const res = await proceedNext(user.uid)
//       if (res?.data?.success) {
        
//         const userData = await getFirebaseUser(user.uid)
//         console.log({ 'status': true, 'message': 'Successfully registered', 'user': userData })
//         return { 'status': true, 'message': 'Successfully registered', 'user': userData }
//       }else if (!res?.data?.success) {
        
//         return { 'status': false, 'message': res?.data?.error }
//       }else{
//         return { 'status': false, 'message': 'something went wrong' }

//       }
//     }
//     if (localStorage.getItem('data') === null) {
//       const userData = await getFirebaseUser(user.uid)
//       console.log({ 'status': true, 'message': 'Welcome back', 'user': userData })
//       return { 'status': true, 'message': 'Welcome back', 'user': userData }
//     }
//     const res = await proceedNext(user.uid)
//       if (res?.data?.success) {
        
//         const userData = await getFirebaseUser(user.uid)
//         return { 'status': true, 'message': 'Welcome back', 'user': userData }
//       }else if (!res?.data?.success) {
        
//         return { 'status': false, 'message': res?.data?.error }
//       }else{
//         return { 'status': false, 'message': 'something went wrong' }

//       }
    
//   } catch (err) {
//     console.error(err);
//     return { 'status': false, 'message': err.message }
//   }
// };

export function create_hash(combined) {
  const salt = 'ask-gpt.ai-back';

  const combinedString = combined + salt;

  // const hash = createHash('sha256').update(combinedString).digest('hex');
  const hash = SHA256(combinedString).toString();
  // console.log(hash)
  return hash;
}
export function generateRandomCode() {
  // Generate a random number between 0 and 9999
  let randomNumber = Math.floor(Math.random() * 10000);

  // Pad the number with leading zeros to ensure it has 4 digits
  let paddedNumber = randomNumber.toString().padStart(4, "0");

  return paddedNumber;
}




// export async function proceedNext(uid) {



//   if (localStorage.getItem('data') !== null) {

//     const storedData = JSON.parse(localStorage.getItem('data'));
//     const storedTimestamp = storedData.timestamp;
//     const currentTimestamp = Date.now();
//     if (currentTimestamp - storedTimestamp <= 20 * 60 * 1000) {
//       // The stored data is less than 20 minutes old
//       const token = storedData.token;
//       // Do something with the stored value
//       const data = {
//         first_name: storedData.firstname,
//         last_name: storedData.lastName,
//         email: storedData.email,
//         token: token,
//         uid: uid
//       }
//       console.log(data)
//       const hash_val = create_hash(storedData.firstname, storedData.lastName, storedData.email, token, uid)
//       try{

//         const response = await axios.post('https://backend.ask-gpt.ai/subscribe', data, {
//         // const response = await axios.post('http://127.0.0.1:5000/subscribe', data, {
//           // await axios.post('http://127.0.0.1:5000/subscribe', data,{
//             headers: {
//               'xxx-header': `${hash_val}`
//             }
//           })
//           localStorage.removeItem('data');
//           console.log(response)
//           return response
          
//         }
//         // .then(response => {
//           //   console.log(response.data);
//           // })
//           // .
//           catch(error ) {
//             console.error(error);
//             ErrorToast(`${error}`,2000)
//             localStorage.removeItem('data');
//         }
//         return null

//     } else {
//       // The stored data is more than 20 minutes old
//       // Remove the data from local storage
//       localStorage.removeItem('data');
//     }
//   }
// }
// export async function getFirebaseUser(uid) {
//   const docRef = doc(db, "users", uid);
//   const docSnapshot = await getDoc(docRef)

//   return docSnapshot.data()
// }

// const logInWithEmailAndPassword = async (email, password) => {
//   try {

//     const user = await signInWithEmailAndPassword(auth, email, password);

//     if (user.user.emailVerified) {
//       if (localStorage.getItem('data') === null) {
//         const userData = await getFirebaseUser(user.user.uid)
//           return { 'status': true, 'message': 'Welcome Back', 'user': userData }
//       }else{
//         const res = await proceedNext(user.user.uid)
      
//         if (res?.data?.success) {
          
//           const userData = await getFirebaseUser(user.user.uid)
//           return { 'status': true, 'message': 'Successfully registered', 'user': userData }
//         }else if (!res?.data?.success) {
          
//           return { 'status': false, 'message': res?.data?.error }
//         }else{
//           return { 'status': false, 'message': 'something went wrong' }
  
//         }
//       }
      
    
//     } else {
//       auth.signOut()
//       return { 'status': false, 'message': 'Please verify your email first' };
//     }

//   } catch (err) {
//     console.error(err);

//     return { 'status': false, 'message': err.message }
//   }
// };
// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     const docRef = doc(db, "users", user.uid);


//     await setDoc(docRef, {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//       accountId: '',
//       subscribed: false
//     });

//     await sendEmailVerification(auth.currentUser)
//       .then(() => {
//         console.log("sent")
//       }).catch((err) => alert(err.message))
//     auth.signOut()


//     return { 'status': true, 'message': 'Email Verification Link Sent' }
//     // proceedNext(user.uid)
//   } catch (err) {
//     console.error(err);
//     return { 'status': false, 'message': err.message }


//   }
// };
// const sendPasswordReset = async (email) => {
//   try {
//     await sendPasswordResetEmail(auth, email);
//     return { 'status': true, 'message': 'Password reset link sent' }
//   } catch (err) {
//     console.error(err);
//     return { 'status': false, 'message': err.message }
//   }
// };
// const logout = () => {
//   signOut(auth);

// };
// export {
//   auth,
//   db,
//   signInWithGoogle,
//   logInWithEmailAndPassword,
//   registerWithEmailAndPassword,
//   sendPasswordReset,
//   logout,
// };


// export async function checkUserSubscription(uid) {
//   const docRef = doc(db, "users", uid);
//   const docSnapshot = await getDoc(docRef)

//   return docSnapshot.data()
// }