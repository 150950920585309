import React from 'react';

function Dialog(props) {
  return (
    <div className="dialog-background px-2">
      <div className="dialog-content">
        {props.children}
      </div>
    </div>
  );
}

export default Dialog;