import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonWig } from '../../widgets/ButtonWig'
import { InputField } from '../../widgets/InputField'
// import {  sendPasswordReset } from "../../service/firebase-service";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { RoutesName } from '../../utils/RoutesName'
import { ErrorToast, SuccessToast } from '../../utils/Toast-Wig'


export const ResetPassword = () => {
 const navigate = useNavigate()
 const [email, setEmail] = useState("")
  // const [user, loading] = useAuthState(auth);
  const [loadingCheck,setLoadingCheck] = useState(false)

  // useEffect(() => {
  //   if (loading) {
  //     // maybe trigger a loading screen
  //     return;
  //   }
  //   if (user) navigate(RoutesName.chat);
  // }, [user, loading]);

 async function handleSubmit(){
    // if((email !== "" && email !== 'undefined' && email != null) ){
    //   setLoadingCheck(true)
    //   // const res = await sendPasswordReset(email);
    //   setLoadingCheck(false)
    //  if(res.status){
    //   SuccessToast(res.message,2000)
    //   setEmail('')
    // }else{
    //   ErrorToast(res.message,2000)
    // }
    // }else{
    //   alert('please enter valid email')
    // }
  }
  
    return (
    <>

        <h5 className='fw-bold mt-3 mb-4'>Reset Password</h5>
        <InputField type='email' placeholder='Email' value={email}  setValue={setEmail} />
        <ButtonWig className="mt-3 mb-5 text-white fw-bold w-100 " text={"Reset"} onClick={handleSubmit} loading={loadingCheck}/>
        


    </>
  )
}
