import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/pen.svg";
import img2 from "../../assets/email.svg";
import img3 from "../../assets/book.svg";
import img4 from "../../assets/chat.svg";
import img5 from "../../assets/graduation-hat.svg";
import img6 from "../../assets/creative-brain.svg";
import circleBg from "../../assets/circle-bg.png";
import { RoutesName } from "../../utils/RoutesName";
import { ButtonWig } from "../../widgets/ButtonWig";

export const OnboardingTwo = () => {
  const navigate = useNavigate();

  const chatList = [
    { id: 1, text: "Creating written content", image: img1 },
    { id: 2, text: "Composing high-quality emails", image: img2 },
    { id: 3, text: "Facilitating language learning", image: img3 },
    { id: 4, text: "Keeping up any conversation", image: img4 },
    { id: 5, text: "Assisting in your studies", image: img5 },
    { id: 6, text: "Brainstorming ideas", image: img6 },
  ];

  return (
    <div className="main-bg" style={{ overflowX: "hidden" }}>
      <div style={{ position: "relative" }}>
        <img
          alt=""
          className="bg-circle"
          src={circleBg}
          style={{ top: "0px" }}
        />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ padding: "25px 0px" }}
        >
          <div className="chat-right">
            <p className="m-0 p-0">Hey! What can you do?</p>
          </div>
          <div className="position-relative left-chat mb-5">
            <p>
              Greetings! My skillset includes, but is
              <br /> not limited to:
            </p>
            {chatList.map((data) => (
              <div
                key={data.id}
                className="d-flex flex-row chat align-items-center"
              >
                <img alt="" src={data.image} className="me-2 mb-2" />
                <p className="m-0 p-0 mb-2 fw-bold">{data.text}</p>
              </div>
            ))}
            <p className="m-0 p-0 mt-2">and much more!</p>
          </div>
        </div>
      </div>
      <div className="custom">
        <p className="personal-ai p-0 m-0 mt-3 position-relative">
          <span>Ask Anything</span> <br />
          You Want
        </p>
        <ButtonWig
          className="mt-4 text-white fw-bold continue-btn"
          text={"Continue"}
          onClick={() => navigate(RoutesName.onboardingThree)}
        />
        <div className="d-flex flex-row align-self-center mt-3 justify-content-center">
          <div className="ob-dot"></div>
          <div className="ob-dot-max"></div>
          <div className="ob-dot"></div>
        </div>
      </div>
    </div>
  );
};
