import React from 'react'
import '../ChatlLayout/chatscreen.css'
import { LinkButton } from '../../widgets/LinkButton';
import { Divider } from '../../widgets/Divider';
import { RoutesName } from '../../utils/RoutesName';
import { useNavigate } from 'react-router-dom';
export const ManageSubscription = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="bg-black" style={{'padding':'15px 36px','marginTop':'60px'}}>
        <div className='text-center'>
        <h6 className='fw-bolder m-0' style={{'fontSize':'20px'}}>Manage Subscription</h6>
          <div className='w-100 bg-green p-3 text-start rounded-3 ' style={{'marginTop':'25px'}}>
            <h1 className='fw-bolder m-0' style={{'fontSize':'35px'}}>Active</h1>
          </div>
        </div>

          


        
      </div>
      
    </>

  );
}
