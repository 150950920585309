import React from 'react'
import { Divider } from '../../widgets/Divider'
import BackLogo from '../../assets/back-logo.svg'
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div className='text-white' style={{ 'height': '100dvh', 'display': 'flex', 'flexDirection': 'column', 'overflow': 'hidden' }}>
      <div className="d-flex align-items-center" style={{ height: 74, padding: '20px 36px' }}>
        <img src={BackLogo} alt="" onClick={() => handleGoBack()} style={{ order: -1 }} />
        <h5 className="fw-bolder my-0 mx-auto">Privacy Policy</h5>
      </div>

      <Divider className={'m-0'} />
      <div className='' style={{ 'flexGrow': '1', 'overflowY': 'auto', padding: '44px 25px' }}>


        <h5 >Date: 05 May 2023</h5>        
        <br />

        <b>Our Commitment to Your Privacy</b>
        <br />
        <br />
        We respect and protect the privacy of our WebApp users. This Privacy Policy explains how we collect and use your information and is part of our Terms of Use when you use our WebApp. By using our WebApp and the service provided via the WebApp (“Service”), you consent to our Privacy Policy. If you don’t agree, please don’t use our WebApp.
        <br />
        <br />
        <h4>What information do we collect?</h4>
        <br />
        <br />
        If you make purchases or buy a subscription, our third-party billing service providers will collect information such as your full name, credit card number, and billing address. If you email us, we may use your email address to reply to your email. Information Sent by Your Mobile Device. We collect certain information that your mobile device sends when you use our WebApp, like a device identifier, user settings and the operating system of your device, as well as information about your use of our Services. Location Information. When you use our WebApp, we may collect and store information about your location by converting your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect location information, you may disable that feature on your mobile device.
        <br />
        <br />
        <h4>Where do we store your information?</h4>
        <br />
        <br />
        Your information may be stored in our own servers or in servers owned by third-party cloud storage providers. Third-party storage providers may not use your information except as provided in this Privacy Policy.
        <br />
        <br />
        <h4>How do we use your information?</h4>
        <br />
        <br />
        Information we collect from you might be used:
        <br />
        <br />
        <ul>
          <li>To communicate with you or third parties</li>
          <li>To process your requests and transactions</li>
          <li>To provide you with the Service</li>
          <li>To improve our WebApp and the Service</li>
          <li>To perform marketing activities</li>
          <li>To respond to your messages and comments</li>
          <li>For other purposes related to our business</li>
        </ul>







        <br />
        <br />
        <h4>How long do we store your information?</h4>
        <br />
        <br />
        We intend to store some of your information indefinitely. What about links to other websites? We may provide links to or compatibility with other websites. However, we’re not responsible for the privacy practices employed by those websites or the information or content they contain.
        <br />
        <br />
        <h4>How do we protect your information?</h4>
        <br />
        <br />
        We use appropriate physical, electronic, and other procedures to safeguard and secure the information we collect. However, please be aware that the Internet is an inherently unsafe environment, and that hackers are constantly working to defeat security measures. Thus, we cannot guarantee that your information will not be accessed, disclosed, altered or destroyed, and you accept this risk.
        <br />
        <br />
        <h4>How can you protect your information?</h4>
        <br />
        <br />
        We urge you to take steps to keep your personal information safe by not sharing it with others or posting it online.
        <br />
        <br />
        <h4>Do we disclose any information to outside parties?</h4>
        <br />
        <br />
        We do not collect, sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information (PII) for commercial or marketing purposes, except as stated herein. Personal information (and non- personal information) collected through the Site may be shared with companies and organizations that perform services on our behalf (for example, companies that provide data management or other support services to us such as data storage and Web hosting services). We may share your personal information (and non-personal information) with third parties to serve you relevant advertising and market our products, but we won’t sell your personal information. Information Disclosed in Connection with Business Transactions. If we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third-party acquirer in connection with the transaction. Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.
        <br />
        <br />
        <h4>Not Intended for Children</h4>
        <br />
        <br />
        Our WebApp and Service are not intended for children under the age of 16. We do not knowingly or specifically collect information from or about children under the age of 16.
        <br />
        <br />
        <h4>Changes to our Privacy Policy</h4>
        <br />
        <br />
        If we decide to change our Privacy Policy, we will post those changes on this page. We may also, but are not required to, send you an email notice. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
        <br />
        <br />
        <h4>Contact Us</h4>
        <br />
        <br />
        If you have questions about our Privacy Policy, please contact us at support@ask-me.app
        <br />
        <br />






      </div>
    </div>
  )
}
