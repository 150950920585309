import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { LinkButton } from "../../widgets/LinkButton";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer
      id="footer"
      className="mt-2 d-flex flex-column text-center align-items-center justify-content-center"
    >
      <LinkButton
        text={"Terms of Use"}
        className="lh-base mt-2 footer-text"
        onClick={() => {
          navigate("#footer");
          navigate(RoutesName.terms);
        }}
      />
      <LinkButton
        text={"Privacy Policy"}
        className="lh-base mt-2 footer-text"
        onClick={() => {
          navigate("#footer");
          navigate(RoutesName.privacy);
        }}
      />

      <LinkButton
        text={"support@ask-me.app"}
        className="lh-base mt-2 footer-text"
        onClick={(e) => {
          window.location.href = "mailto:support@ask-me.app";
        }}
      />

      <p className="light-text thin-text mt-4">
        Copyright ® 2024 AskME. <br />
        All Rights Reserved.
      </p>
    </footer>
  );
};
