import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Layout } from "./components/Layout/Layout";
import { EmailLogin } from "./components/Login/EmailLogin";
import { Login, OTP } from "./components/Login/OtpVerification";
import { OnBoarding } from "./components/OnBoarding/OnBoarding";
import { Register } from "./components/Payment/Payment";
import { RoutesName } from "./utils/RoutesName";
import ChatLayout from "./components/ChatlLayout/ChatLayout";
import { SignUp } from "./components/SignUp/SignUp";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./service/firebase-service";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOnlineStatus from "./utils/InternetConnection";
import { ConnectionNotification } from "./widgets/ConnectionNotification";
import { RecurlyProvider, Elements } from "@recurly/react-recurly";
import { TermsConditions } from "./components/LegalPages/TermsConditions";
import { PrivacyPolicy } from "./components/LegalPages/PrivacyPolicy";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import React from "react";
import { ErrorToast } from "./utils/Toast-Wig";
import LayoutManageSub from "./components/ChatlLayout/LayoutManageSub";
import { RegisterNew } from "./components/Payment/PaymentNew";
import { OnboardingTwo } from "./components/OnBoarding/OnBoardingTwo";
import { OnboardingThree } from "./components/OnBoarding/OnBoardingThree";
const MainLoader = () => {
  const state = useSelector((state) => state.authState);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (state.loggedIn) {
        navigate(RoutesName.chat);
      } else {
        navigate(RoutesName.index);
      }
    }, 1000);
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center text-green"
      style={{ height: "100dvh" }}
    >
      <div
        className="spinner-border spinner-border-md text-green mx-auto my-auto"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

function App() {
  const { isOnline, showNotification } = useOnlineStatus();
  const state = useSelector((state) => state.authState);
  // console.log(state)
  const dispatch = useDispatch();

  return (
    <div className="full-section">
      <RecurlyProvider publicKey="ewr1-GBa4qr35ahA1cfU95Zb7Vy">
        <Elements>
          {showNotification && <ConnectionNotification isOnline={isOnline} />}

          <Router>
            <Routes>
              {state.loggedIn ? (
                <>
                  <Route
                    path={RoutesName.chat}
                    element={<ChatLayout isTry={false} />}
                  />
                  <Route
                    path={RoutesName.manage}
                    element={<LayoutManageSub />}
                  />
                </>
              ) : (
                <>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route
                      path={RoutesName.loginWithEmail}
                      element={<EmailLogin />}
                    />
                    <Route
                      path={RoutesName.resetPassword}
                      element={<ResetPassword />}
                    />
                  </Route>
                  <Route
                    path={RoutesName.onBoardingTwo}
                    element={<OnboardingTwo />}
                  />
                  <Route
                    path={RoutesName.onboardingThree}
                    element={<OnboardingThree />}
                  />
                  {/* <Route path={RoutesName.chat} element={<ChatLayout isTry={true} />} /> */}
                </>
              )}
              {/* <Route path={RoutesName.manage} element={<LayoutManageSub />} /> */}
              <Route path={RoutesName.login} element={<OTP />} />

              <Route path={RoutesName.terms} element={<TermsConditions />} />
              <Route path={RoutesName.privacy} element={<PrivacyPolicy />} />
              <Route path={RoutesName.register} element={<RegisterNew />} />

              <Route path="*" element={<MainLoader />} />
            </Routes>
          </Router>
          <ToastContainer />
        </Elements>
      </RecurlyProvider>
    </div>
  );
}

export default App;
