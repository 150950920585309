import { useRecurly } from "@recurly/react-recurly";
import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Turnstile from "react-turnstile";
import fl4 from "../../assets/ad-free.svg";
import pt5 from "../../assets/apay.svg";
import ApplePayLogo from "../../assets/apple_pay.svg";
import AppleLogo from "../../assets/awesome-apple-pay.26a4b520.svg";
import fl2 from "../../assets/chat.svg";
import fl1 from "../../assets/check.svg";
import CloseIcon from "../../assets/close.svg";
import pt4 from "../../assets/jcb.svg";
import fl6 from "../../assets/laurel-wreath.svg";
import pt3 from "../../assets/maestro.svg";
import pt2 from "../../assets/mastercard.svg";
import fl5 from "../../assets/piggy-bank.svg";
import fl3 from "../../assets/rocket.svg";
import pt1 from "../../assets/visa.svg";
import ApiService from "../../service/api";
import {
  create_hash,
  generateRandomCode,
} from "../../service/firebase-service";
import { RoutesName } from "../../utils/RoutesName";
import {
  ErrorToast,
  ErrorToastBottom,
  SuccessToastBottom,
} from "../../utils/Toast-Wig";
import { BASE_URL } from "../../utils/constants";
import { ButtonWig } from "../../widgets/ButtonWig";
import Dialog from "../../widgets/Dialog";
import { InputField } from "../../widgets/InputField";
import { Loader } from "../../widgets/Loader";
import { Footer } from "../Footer/Footer";

const featuresList = [
  { id: 1, image: fl1, label: "7-Day Free Trial" },
  { id: 2, image: fl2, label: "Unlimited chat messages" },
  { id: 3, image: fl3, label: "Faster GPT-4 model" },
  { id: 4, image: fl4, label: "Ad-free experience" },
  { id: 5, image: fl5, label: "Best value" },
  { id: 6, image: fl6, label: "Best Mobile App" },
];

const paymentTypeList = [
  { id: 1, image: pt1 },
  { id: 2, image: pt2 },
  { id: 3, image: pt3 },
  { id: 4, image: pt4 },
  { id: 5, image: pt5 },
];

export const RegisterNew = () => {
  const recurly = useRecurly();
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const currentYear = moment().year().toString().slice(2, 4);

  const formRef = useRef();
  const captchaRef = useRef();
  const emailRef = useRef();
  const cardNumberRef = useRef();
  const cardMonthRef = useRef();
  const cardYearRef = useRef();
  const cardCvvRef = useRef();

  const navigate = useNavigate();
  const [proceed, setProceed] = useState(false);
  const [proceedApple, setProceedApple] = useState(false);
  const [email, setEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardMonthError, setCardMonthError] = useState(false);
  const [cardYearError, setCardYearError] = useState(false);
  const [cardCvvError, setCardCvvError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorFocus, setEmailErrorFocus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds
  const [applePayEmail, setApplePayEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [appleAvailable, setAppleAvailable] = useState("");
  const [isSupport, setIsSupport] = useState(true);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [applePayLoading, setApplePayLoading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 1) {
          return 900;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const divideStringIntoHalf = () => {
    const nameString = email?.split("@")[0];
    const length = nameString?.length;
    const middle = Math.floor(length / 2);

    const firstHalf = nameString?.slice(0, middle);
    setFirstName(firstHalf);
    const secondHalf = nameString?.slice(middle);
    setLastName(secondHalf);
  };

  useEffect(() => {
    divideStringIntoHalf();
  }, [email]);

  const handleCardNumberChange = (e) => {
    const inputVal = e.target.value;
    const inputCardNumber = e.target.value.replace(/\D/g, "");
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    setCardNumber(formattedCardNumber);
    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;

    !inputVal || length < 15 || length > 16
      ? setCardNumberError(true)
      : setCardNumberError(false);

    if (length === 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardMonthChange = (e) => {
    const length = e.target.value.length;
    setCardMonth(e.target.value);
    length < 2 || e.target.value > 12
      ? setCardMonthError(true)
      : setCardMonthError(false);
    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardYearChange = (e) => {
    const length = e.target.value.length;
    setCardYear(e.target.value);
    length < 2 || e.target.value < currentYear
      ? setCardYearError(true)
      : setCardYearError(false);
    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardCvvChange = (e) => {
    const length = e.target.value.length;
    setCardCvv(e.target.value);
    length < 3 ? setCardCvvError(true) : setCardCvvError(false);
    if (length === 3) {
      setCardCvvError(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!verified) {
      return;
    }

    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("number", cardNumber);
    formData.append("month", cardMonth);
    formData.append("year", cardYear);
    formData.append("cvv", cardCvv);
    formData.append("email", email);
    formData.append("three_d_secure_action_required", true);
    formData.append("key", "ewr1-GBa4qr35ahA1cfU95Zb7Vy");

    if (email && cardNumber && cardMonth && cardYear && cardCvv) {
      await ApiService.getRecurlyToken(formData).then(async (response) => {
        if (
          !cardNumberError &&
          !cardYearError &&
          !cardMonthError &&
          !cardCvvError &&
          !response.message
        ) {
          const code = generateRandomCode();
          const token = response?.data?.id;
          const combined = email.toLowerCase() + token + code;
          const hash_val = create_hash(combined);
          try {
            const response = await axios.post(
              BASE_URL + "/subscribe",
              {
                email: email.toLowerCase(),
                token: token,
                code: code,
              },
              {
                headers: {
                  "xxx-header": `${hash_val}`,
                },
              }
            );

            console.log(response);
            setLoadingPayment(true);

            if (response.data.success) {
              const hash_value_session = create_hash(
                email.toLowerCase() + code.toString()
              );
              console.log(hash_value_session);
              localStorage.setItem("verification", hash_value_session);
              localStorage.setItem(
                "data",
                JSON.stringify({
                  code: code,
                  email: email.toLowerCase(),
                  token: token,
                  timestamp: Date.now(),
                })
              );

              SuccessToastBottom("please verify your email", 5000);
              setLoadingPayment(false);
              navigate(RoutesName.login);
            } else {
              ErrorToastBottom(response.data.error, 3000);
              setLoadingPayment(false);
            }
          } catch (e) {
            setLoadingPayment(false);
            console.log(e.err);
          }
        }
      });
    }
  };

  function validateEmail(email) {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return pattern.test(email);
  }

  useEffect(() => {
    if (validateEmail(email) && emailErrorFocus) {
      setEmailError(false);
    } else if (emailErrorFocus) {
      setEmailError(true);
      emailRef.current.focus();
    }
  }, [email]);

  useEffect(() => {
    const applePayOptions = {
      requiredBillingAddressFields: ["all"],
      requiredShippingContactFields: ["phone", "email", "postalAddress"],
      countryCode: "US",
    };

    recurly.configure({
      applePay: applePayOptions,
    });
  }, [recurly]);

  useEffect(() => {
    if (!window.ApplePaySession) {
      console.log("Your device or browser is not compatible with Apple Pay ");
      if (appleAvailable !== "Not Available") {
        setIsSupport(false);
        setAppleAvailable("Not Available");
      }
      return;
    }
  }, [isPaymentProcessing]);

  const sendData = async (token) => {
    setApplePayLoading(true);
    const code = generateRandomCode();

    const emailValue = emailRef.current.value;
    const emailVal = emailValue.toLowerCase();
    console.log("[token]", token);
    const combined = emailVal + token.id + code;
    const hash_val = create_hash(combined);
    try {
      const response = await axios.post(
        BASE_URL + "/subscribe",
        {
          email: emailVal,
          token: token.id,
          code: code,
        },
        {
          headers: {
            "xxx-header": `${hash_val}`,
          },
        }
      );

      console.log(response);
      if (response.data.success) {
        const hash_value_session = create_hash(emailVal + code.toString());
        console.log(hash_value_session);
        localStorage.setItem("verification", hash_value_session);
        localStorage.setItem(
          "data",
          JSON.stringify({
            code: code,
            email: emailVal.toLowerCase(),
            token: token.id,
            timestamp: Date.now(),
          })
        );

        SuccessToastBottom("please verify your email", 5000);
        navigate(RoutesName.login);
        setApplePayLoading(false);
      } else {
        ErrorToastBottom(response.data.error, 3000);
      }
    } catch (e) {
      console.log(e.err);
    }
    setPaymentProcessing(false);

    setApplePayLoading(false);
  };

  const applePay = recurly.ApplePay({
    country: "US",
    currency: "USD",
    label: "AskME 1 month for $24.99/month (billed every month)", // This text will be displayed on the Apple Pay payment sheet as "My Subscription"
    total: "1.00",
  });

  const handleAppleClick = (event) => {
    event.preventDefault();
    let showErr = false;

    if (email === "") {
      setEmailError(true);
      setEmailErrorFocus(true);
      showErr = true;
    }
    if (showErr) {
      console.log("error");
      showErr = false;
      return;
    }

    applePay?.ready(function () {
      applePay?.begin();
    });

    applePay.on("error", function (err) {
      console.error("Apple Pay error", err);
      Showerror(err);
    });

    applePay.on("token", async function (token) {
      await sendData(token);
    });
  };

  function Showerror(err) {
    setAppleAvailable(err.message);
    setIsSupport(false);
    ErrorToastBottom(err.message, 3000);
  }

  useEffect(() => {
    const script = document.createElement("script");

    if (proceed) {
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.async = true;

      document.body.appendChild(script);
    }
  }, [proceed]);

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const section = document.querySelector(hash);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const features = () =>
    featuresList?.map((data) => (
      <div key={data.id} className="mb-3 d-flex flex-row align-items-center">
        <img src={data.image} alt="" />
        <p
          className={`m-0 p-0 ms-2 ${data?.id === 1 ? "fw-bold" : ""}`}
          style={{ fontSize: "18px" }}
        >
          {data?.label}
        </p>
      </div>
    ));

  const paymentTypes = () => (
    <div className="d-flex flex-row align-items-center">
      {paymentTypeList?.map((data) => (
        <img
          className="mt-4 mb-3 mx-2"
          key={data.id}
          src={data.image}
          alt=""
          style={{ opacity: "0.6" }}
        />
      ))}
    </div>
  );

  return (
    <>
      <div className="video-background">
        <div className="content padding-main" style={{ paddingTop: "20%" }}>
          <div className="px-4 text-start">
            <h1 className="m-0 p-0 payment-title mb-3">
              Unlock Full Potential of <br />
              <span>AI CHAT</span> for $1
            </h1>
            {features()}
          </div>
          <form onSubmit={handleSubmit} ref={formRef} className="w-100 index">
            <div className="w-100">
              {proceed ? (
                <>
                  <div className="text-start w-100 mt-4">
                    <p className="mt-3 mb-1 p-0" style={{ fontSize: "14px" }}>
                      Email
                    </p>
                    <input
                      ref={emailRef}
                      type={"text"}
                      data-recurly={"email"}
                      className={` input-field ${
                        emailError ? "border-red" : "border-grey"
                      }`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      onFocus={(e) => {
                        e.preventDefault();
                      }}
                      style={{ height: "40px" }}
                      required
                    />
                    {emailError ? (
                      <p className="small  " style={{ color: "red" }}>
                        The email field is required
                      </p>
                    ) : null}

                    <div className="bg-dark rounded-3 p-3 mt-4">
                      <p className="mb-1 p-0" style={{ fontSize: "14px" }}>
                        Card Number
                      </p>
                      <input
                        ref={cardNumberRef}
                        className={` input-field-card recurly-element-inline  ${
                          cardNumberError ? "border-red" : "border-green"
                        }`}
                        id="cardNumber"
                        name="cardNumber"
                        type="text"
                        placeholder="XXXX XXXX XXXX XXXX"
                        required
                        value={cardNumber}
                        data-recurly={"number"}
                        onChange={(event) => handleCardNumberChange(event)}
                        inputMode="numeric"
                        autoComplete="off"
                        style={{ fontFamily: "Helvetica", padding: "5px 15px" }}
                      />
                      {cardNumberError && (
                        <p className="small  " style={{ color: "red" }}>
                          The card number field is required
                        </p>
                      )}
                      <div className="d-flex mt-4">
                        <div className="w-100">
                          <p className="mb-1 p-0" style={{ fontSize: "14px" }}>
                            Expire on
                          </p>
                          <div className="d-flex">
                            <input
                              ref={cardMonthRef}
                              className={`input-field-card ${
                                cardMonthError ? "border-red" : "border-green"
                              } w-50 p-2 me-1 text-center`}
                              id="cardMonth"
                              name="cardMonth"
                              type="text"
                              placeholder="MM"
                              required
                              value={cardMonth}
                              data-recurly={"month"}
                              onChange={(event) => handleCardMonthChange(event)}
                              inputMode="numeric"
                              autoComplete="off"
                              style={{ fontFamily: "Helvetica" }}
                              maxLength={2}
                            />
                            <input
                              ref={cardYearRef}
                              className={`input-field-card ms-2 ${
                                cardYearError ? "border-red" : "border-green"
                              } w-50 p-2 ms-1 text-center`}
                              id="cardYear"
                              name="cardYear"
                              type="text"
                              placeholder="YY"
                              required
                              value={cardYear}
                              data-recurly={"year"}
                              onChange={(event) => handleCardYearChange(event)}
                              inputMode="numeric"
                              autoComplete="off"
                              style={{ fontFamily: "Helvetica" }}
                              maxLength={2}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end w-75">
                          <div className="w-75 ">
                            <p
                              className="mb-1 p-0"
                              style={{ fontSize: "14px" }}
                            >
                              CVV
                            </p>
                            <input
                              ref={cardCvvRef}
                              className={` input-field-card  text-white  text-center w-100 ${
                                cardCvvError ? "border-red" : "border-green"
                              } w-100 mt-1 p-2 text-center`}
                              id="cardYear"
                              name="cardYear"
                              type="text"
                              placeholder="CVV"
                              required
                              value={cardCvv}
                              data-recurly={"cvv"}
                              onChange={(event) => handleCardCvvChange(event)}
                              inputMode="numeric"
                              autoComplete="off"
                              style={{ fontFamily: "Helvetica" }}
                              maxLength={4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Turnstile
                    userRef={captchaRef}
                    className="mt-3"
                    theme="dark"
                    autoResetOnExpire={true}
                    sitekey="0x4AAAAAAAGIBZ5kqo0rcwME"
                    onVerify={(token) => {
                      const form = new FormData();
                      form.append("token", token);
                      console.log(token);
                      axios
                        .post(BASE_URL + "/handle_post", form)
                        .then((res) => {
                          console.log(res.data);
                          if (res.data.success) {
                            setVerified(true);
                            console.log("verified");
                          }
                        })
                        .catch((e) => console.log(e));
                    }}
                    onExpire={() => {
                      setVerified(false);
                    }}
                    onError={() => {
                      setVerified(false);
                    }}
                  />
                </>
              ) : null}
            </div>

            {loadingPayment ? (
              <Loader />
            ) : !proceed ? (
              <ButtonWig
                text={"Process Card"}
                className="text-white mt-4 mb-4 w-100 continue-btn"
                onClick={() => {
                  setProceed(true);
                  setProceedApple(false);
                }}
                showArrow={true}
              />
            ) : (
              <ButtonWig
                text={"Process Card"}
                className="text-white mt-4 mb-4 w-100 continue-btn"
                onClick={null}
                type="submit"
                showArrow={true}
              />
            )}
            {proceedApple && (
              <div className="mb-3 text-start w-100">
                <p className="m-0 p-0 mb-1" style={{ fontSize: "14px" }}>
                  Email
                </p>
                <input
                  ref={emailRef}
                  type={"text"}
                  data-recurly={"email"}
                  className={` input-field ${
                    emailError ? "border-red" : "border-grey"
                  }`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  onFocus={(e) => {
                    e.preventDefault();
                  }}
                  style={{ height: "40px" }}
                />
                {emailError ? (
                  <p className="small m-0" style={{ color: "red" }}>
                    The email field is required
                  </p>
                ) : null}
              </div>
            )}
            {applePayLoading ? (
              <Loader />
            ) : isSupport ? (
              !proceedApple ? (
                <button
                  onClick={() => {
                    setProceed(false);
                    setProceedApple(true);
                  }}
                  className="w-100 apple-btn"
                >
                  <img src={AppleLogo} alt="" />
                </button>
              ) : (
                <button onClick={handleAppleClick} className="w-100 apple-btn">
                  <img src={AppleLogo} alt="" />
                </button>
              )
            ) : (
              <button
                onClick={() =>
                  ErrorToast(
                    "Your device or browser is not compatible with Apple Pay"
                  )
                }
                className="w-100 apple-btn"
              >
                <img src={AppleLogo} alt="" />
              </button>
            )}
          </form>

          {paymentTypes()}
          <Footer />
        </div>
      </div>
      {showModal ? (
        <Dialog>
          <div className="d-flex justify-content-center mb-3">
            <img src={ApplePayLogo} className="ms-auto" alt="Apple Pay Logo" />
            <img
              src={CloseIcon}
              className="ms-auto"
              style={{ cursor: "pointer" }}
              alt="Apple Pay Logo"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          <p className="thin-text m-0 p-0 mb-3 text-light text-center">
            Please, enter your email address, so that we can send you the
            invoice.
          </p>
          <InputField
            type={"email"}
            placeholder="Email"
            value={applePayEmail}
            setValue={setApplePayEmail}
          />
          <ButtonWig
            className={"w-100 text-light mt-3"}
            text={"Complete Payment"}
          />
        </Dialog>
      ) : null}
    </>
  );
};
