import React, { useEffect, useState } from 'react'
export const RightMessage = ({text,scrollBottom,className}) => {
  const [i, setI] = useState(0);


  useEffect(() => {

    setTimeout(() => {
      setI(1)

    }, 50);

    setTimeout(() => {
      scrollBottom()
    }, 200);


  }, [])

  const [copied, setCopied] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);
    return (
    <div className={`d-flex flex-column  mb-2 ${className}`}>
        <div className="right-message p-2 d-flex justify-content-between align-items-center message" style={{ opacity: i }}>
            <p className='m-0 p-o message' style={{ opacity: i }}>{text}</p>
        </div>
        </div>
    )
}
