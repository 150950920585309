import React from "react";
import { useState } from "react";
import { Loader } from "./Loader";
import arrowNext from "../assets/back.png";

export const ButtonWig = ({
  text,
  className,
  onClick,
  loading,
  type = "button",
  disabled = false,
  showArrow = false,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (loading) {
      return;
    }
    setClicked(true);
    if (onClick != null) {
      onClick();
    }
    // setTimeout(() => {
    //   setClicked(false);
    // }, 200); // change back to original color after 1 second
  };

  return (
    <button
      className={` ${" main-button"} py-3  fw-bold text-center ` + className}
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      {loading ? <Loader /> : text}
      {!showArrow && <img alt="" className="arrow-next" src={arrowNext} />}
    </button>
  );
};
