import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonWig } from "../../widgets/ButtonWig";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  create_hash,
  generateRandomCode,
} from "../../service/firebase-service";
import { RoutesName } from "../../utils/RoutesName";
import { ErrorToastBottom, SuccessToastBottom } from "../../utils/Toast-Wig";
import { BASE_URL } from "../../utils/constants";
import { Loader } from "../../widgets/Loader";
import logo from "../../assets/logo.svg";
import { Footer } from "../Footer/Footer";

export const EmailLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (email.includes("@") && email.includes(".") && email !== "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [email]);

  async function handleSubmit() {
    setLoading(true);
    if (email.includes("@") && email.includes(".")) {
      const code = generateRandomCode();
      const combined = email.toLowerCase() + code;
      const hash_val = create_hash(combined);
      localStorage.setItem(
        "data",
        JSON.stringify({ email: email.toLowerCase(), code: code })
      );
      localStorage.setItem("verification", hash_val);
      try {
        const res = await axios.post(
          BASE_URL + "/send-mail",
          {
            email: email.toLowerCase(),
            code: code,
          },
          {
            headers: {
              "xxx-header": `${hash_val}`,
            },
          }
        );
        console.log(res);
        if (res.data.success && res.data?.data?.state === "active") {
          navigate(RoutesName.login);
          SuccessToastBottom("Verification code sent", 3000);
        } else {
          ErrorToastBottom(res.data.error, 3000);
        }
      } catch (e) {
        console.log(e);
        ErrorToastBottom("Please renew your membership", 3000);
      }
    } else {
      setEmailError(true);
      setEmailFocus(true);
      // ErrorToastBottom('Invalid email',2000)
    }
    setLoading(false);
  }

  return (
    <div className="main-bg">
      <div className="d-flex flex-column align-items-center">
        <img className="logo-main" alt="AskMe" src={logo} />
        <p
          className="m-0 p-0 mt-4"
          style={{ fontSize: "28px", fontWeight: "bold" }}
        >
          AskMe
        </p>
        <h6
          className="fw-light mt-3 px-2 text-center"
          style={{ fontSize: "14px", lineHeight: "17px" }}
        >
          Enter your registered email address
          <br /> to enter the app
        </h6>
        <input
          onFocus={() => setEmailFocus(true)}
          type="email"
          className={`input-field ${
            emailFocus && emailError ? "border-red" : ""
          } mt-4`}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          style={{ height: "40px" }}
        />
        <div className="text-start w-100 mt-1">
          <h6
            className=" p-0 small fw-light "
            style={{ color: "red", fontSize: "12px" }}
          >
            {emailFocus && emailError ? "The first name field is required" : ""}
          </h6>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ButtonWig
            className="mt-4 text-white fw-bold w-100 mb-5 continue-btn"
            text={"Get AskME"}
            onClick={handleSubmit}
            loading={false}
          />
        )}
      </div>
      <div className="footer-bottom-container">
        <Footer />
      </div>
    </div>
  );
};
