import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import SendIcon from '../../assets/send.png'
import { MdSend } from 'react-icons/md'
import { LeftMessage } from '../../widgets/LeftMessage';
import { RightMessage } from '../../widgets/RightMessage';
import { useNavigate } from 'react-router-dom';

import { RoutesName } from '../../utils/RoutesName';
import { Configuration, OpenAIApi } from 'openai';
import { OPEN_AI_API_KEY } from '../../utils/OpenAIConfig';
import { LeftChatLoading } from '../../widgets/LeftChatLoading';
import { ErrorToast, SuccessToast } from '../../utils/Toast-Wig';
import useOnlineStatus from '../../utils/InternetConnection';
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import * as Scroll from 'react-scroll';

const {
  Link,
  Element,
  Events,
  animateScroll: scroll,
  scrollSpy,
  scroller
} = Scroll;
function ChatScreen({ conversation, setConversation, inputValue, setInputValue, isTry }) {
  const isKeyboardOpenCheck = useDetectKeyboardOpen();
  const [loadinRef, setloadinRef] = useState(false)
  // useEffect(() => {
  //   if (isKeyboardOpenCheck) {
  //     scrollToBottom()
  //   }
  // }, [isKeyboardOpenCheck])

  const loadingRef = useRef(false)

  const inputRef = useRef(null);
  const navigate = useNavigate()
  const [inputClicked, setInputClicked] = useState(false)
  const [isMobile, setIsMobile] = useState(false);

  const { isOnline } = useOnlineStatus()
  // const [onceClicked, setOnceClicked] = useState(second)
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /mobile|tablet|ip(ad|hone|od)|android|webos/i.test(userAgent);
    setIsMobile(isMobile);
  }, []);

  // Open AI Config
  const configuration = new Configuration({
    apiKey: OPEN_AI_API_KEY,
    format: "html"
  });
  const openai = new OpenAIApi(configuration);


  const [loadingCheck, setLoadingCheck] = useState(false)

  useEffect(() => {
    
    async function sendChat() {

      if (inputValue.trim() === '') {
        return;
      }
      setLoadingCheck(true)
      setInputValue('')

      const res = await GPT35Turbo(conversation)


      setConversation([...conversation, { ...res }])
      if (conversation.length > 1) {

        scrollToBottom()
      }
      localStorage.setItem('chat', JSON.stringify(conversation))

      setLoadingCheck(false)
      setTimeout(()=>{

        loadingRef.current = false
        console.log("loaded")
      })

    }

    sendChat()
    scrollToBottom()

  }, [conversation])


  const chatScreenRef = useRef(null);
  const scrollToBottom = () => {
    // scroll.scrollToBottom();
    setTimeout(() => {
      chatScreenRef.current?.scrollIntoView({ behavior: 'smooth' });

      // scroller.scrollTo('test1', {
      //   duration: 500,
      //   smooth: true,
      //   offset: -50, // Offset from the top
      // });
      // 
    }, 100);

  };

  const scrollRight = () => {
    setTimeout(() => {
      chatScreenRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  }

  function handleClick() {
    inputRef.current.focus();
    // inputRef.current.blur();
  }

  let GPT35Turbo = async (message) => {
    const response = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: message,
      // format: 'html'
    });

    return response.data.choices[0].message;
  };

  async function handleHistory() {
    // console.log(loadingRef.current)
    console.log(isOnline)
    if (!isOnline || loadingRef.current) {
      return;
    }
    if (isTry) {
      let prompts = localStorage.getItem('prompts') ?? 0
      if (prompts < 3) {
        let number = parseInt(prompts)
        localStorage.setItem('prompts', (number + 1))

      } else {
        navigate(RoutesName.register + "#footer")
      }
    }
    // scrollToBottom()
    const message = inputValue
    console.log(message.length)
    if (message.length > 0 && !loadingRef.current && !loadingCheck) {
      loadingRef.current = true
      // setloadinRef(true)
      // setInputClicked(false)
      handleClick();
      if (conversation.length > 2) {

        scrollToBottom()
      }
      setLoadingCheck(true)
      setConversation([...conversation, {
        role: "user",
        content: message,
      }])
    } else {
      // ErrorToast('Please Type Something!', 1000)
    }


  }
  useEffect(() => {
    if (!isKeyboardOpenCheck) {
      setInputClicked(false)
    } else {
      // setTimeout(()=>{
      setInputClicked(true)
      // },100)
    }

    // return () => {
    //   setInputClicked(false)
    // }
  }, [isKeyboardOpenCheck])

  const originalHeight = window.innerHeight;
  const [chatContainerHeight, setChatContainerHeight] = useState(window.innerHeight);
  const inputFieldRef = useRef(null);

  useLayoutEffect(() => {
    function checkPosition() {
      handleResize()
      const divElement = chatScreenRef.current;
      const rect = divElement.getBoundingClientRect();
      const isBelowScreen = rect.top > window.innerHeight;

      if (isBelowScreen) {
        // Perform your desired action if the div is below the screen height
        setInputClicked(false)
      }
    }
    const handleResize = () => {
      setChatContainerHeight(window.innerHeight - inputFieldRef.current.offsetHeight);
    };

    window.addEventListener('resize', checkPosition);

    return () => {
      window.removeEventListener('resize', checkPosition);
    };
  }, []);

  const myFunction = () => {
    // your logic here
    console.log('pressed Enter ✅');
  };

  useEffect(() => {
    const keyDownHandler = event => {
      // console.log('User pressed: ', event.keyCode);

      if (event.keyCode === 13) {
        event.preventDefault();

        // 👇️ your logic here
        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>

      <div className="chat-screen-body"  >
        <div className={`chat-screen-messages`} style={{ justifyContent: inputClicked ? 'end' : 'start' }}>
          <LeftMessage  key={'ioawidja'} text={'How can I help you today?'} scrollBottom={scrollToBottom} />
          {
            conversation.length > 0 ?
              conversation.map((chat, index) => {
                if (chat.role === 'user') {
                  return <RightMessage className={'mt-2 mb-3'} key={index} text={chat.content} scrollBottom={scrollRight} />
                } else {
                  return <LeftMessage  className={''} key={index} text={chat.content} scrollBottom={scrollToBottom} />
                }
              }
                // ) : <p className='thin-text text-secondary text-center mt-3 small'>Type something to get started!</p>
              ) : <></>
          }
          {loadingCheck && conversation.length > 0 ?
            <LeftChatLoading scrollBottom={() => {
              // if (conversation.length > 2) {
              scrollToBottom()
              // } 
            }} /> : null
          }
          <div ref={chatScreenRef} className=' p-2'></div>
          <Element name="test1" className="element">
          </Element>

        </div>



        <div className="chat-screen-input" ref={inputFieldRef}>

          <div className='d-flex justify-content-between'>
            <input type="text" autoComplete={'none'} autoFocus={false} ref={inputRef} 
            value={inputValue} 

              onClick={() => {

                if (isMobile) {
                  // if (!isKeyboardOpenCheck) {
                  //   setInputClicked(false)
                  // }else{

                  // setInputClicked(true);
                  // }
                  setTimeout(() => {

                    scrollToBottom();
                  }, 1000);
                }
              }}
              onChange={(e) => {
                if (!loadingCheck) {
                  setInputValue(e.target.value)
                }
              } }
               placeholder="Say something..."
            // onKeyDown={(event) => event.key === 'Enter' && handleHistory(event.target.value)}
            />
            {
              
              loadingCheck?
              <button className='image-button bg-transparent text-white' disabled={true} onClick={() => {
                // handleClick()
                console.log("loadi")
               
                // },500)
              }}>
  
                <MdSend className='fs-3' style={{ 'color': '#888888' }} />
              </button>:<button className='image-button bg-transparent text-white' onClick={() => {
              // handleClick()
              console.log("onlcin")
              console.log(!loadinRef, !loadingCheck, isOnline)
              console.log("check",!loadinRef && !loadingCheck && isOnline)
              // setTimeout(()=>{
              if (!loadinRef && !loadingCheck && isOnline) {
                console.log("in")
                handleHistory()
              }
              // },500)
            }}>

              <MdSend className='fs-3' style={{ 'color': inputValue !== '' ? '#10A37F' : '#888888' }} />
              {/* <img src={SendIcon} alt="" width={32} height={27} /> */}
              {/* <h1 className='text-white'> <i className="fa-solid fa-paper-plane-top"></i></h1> */}
            </button>}
          </div>
        </div>
      </div>

    </>
  );
}


export default ChatScreen;
