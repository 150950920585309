import React from 'react';
import Subscription from '../assets/settings/settings.svg'
import Info from '../assets/settings/info.svg'
import Support from '../assets/settings/support.svg'
import Logout from '../assets/settings/logout.svg'
import { useNavigate } from 'react-router-dom';
import { RoutesName } from '../utils/RoutesName';
export const SettingsModal = ({ setShowLogout, setShowSettings, showManageSub }) => {
  const navigate = useNavigate()
  function hideModal() {
    setShowSettings(false);
  }
  return (
    <>
      <div className="settings-modal">
        <div className="settings-modal-content ">
          <div className='d-flex justify-content-start align-items-center  image-button' style={{'paddingBottom':'20px'}} onClick={() => {
            window.location.href = 'mailto:support@ask-me.app';
          }}>
            <img src={Support} alt="support" />
            <p className='m-0 ms-3'>Support</p>
          </div>
          <div className='d-flex justify-content-start align-items-center  image-button' style={{'paddingBottom':'20px'}} onClick={()=>navigate(RoutesName.terms)}>
            <img src={Info} alt="Terms of Service" />
            <p className='m-0 ms-3'>Terms of Service</p>
          </div>
          <div className='d-flex justify-content-start align-items-center  image-button' style={{'paddingBottom':'20px'}} onClick={()=>navigate(RoutesName.privacy)}>
            <img src={Info} alt="privacy" />
            <p className='m-0 ms-3'>Privacy Policy</p>
          </div>
          <div className='d-flex justify-content-start align-items-center  image-button' style={{'paddingBottom':'20px'}} onClick={() => {
            hideModal();
            navigate(RoutesName.manage)
          }}>
            <img src={Subscription} alt="subscription management" />
            <p className='m-0 ms-3'>Manage Subscription</p>
          </div>
          <div className='d-flex justify-content-start align-items-center  image-button'  onClick={() => { hideModal(); setShowLogout(true) }}>
            <img src={Logout} alt="subscription management" />
            <p className='m-0 ms-3'>Logout</p>
          </div>
        </div>
      </div>

    </>

  );
}


