import { createSlice,createAsyncThunk  } from '@reduxjs/toolkit'
import { checkUserSubscription, create_hash, getFirebaseUser } from '../service/firebase-service';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../service/firebase-service";
import axios from 'axios';
import { useEffect } from 'react';

function check_session() {
  if (localStorage.getItem('session') !== null) {
    const session = localStorage.getItem('session')
    const data = JSON.parse(localStorage.getItem('data'))
    const hash_val = create_hash(data?.email)
    // console.log(session,data,hash_val)
    if (hash_val === session) {
      return true
    }
    else{
      return false
    }
  }
  return false
}

  const initialState = {
    user: localStorage.getItem('data') !== null? JSON.parse(localStorage.getItem('data')):null,
    loggedIn: check_session()
    };


export const authSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    logoutState: (state) => {
      console.log("logout")
      localStorage.removeItem('session')
      localStorage.removeItem('verification')
      localStorage.removeItem('data')
      state.loggedIn = false
      state.subscribed = false
      state.user = null
    },
    checkLogin: (state) => {
      // state.value -= 1
      state.loggedIn = check_session()
    },
    makeLogin: (state, action) => {
      // state.value += action.payload
    },
  }
 
})

// Action creators are generated for each case reducer function
export const { logoutState, checkLogin, incrementByAmount } = authSlice.actions

export default authSlice.reducer