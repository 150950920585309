import axios from "axios";

export const API_URL = "";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const recurlyUrl = axios.create({
  baseURL: "https://ask-me.recurly.com/js/v1/",
});

export default class ApiService {
  static getRecurlyToken(data = {}) {
    return recurlyUrl
      .post("token", data, {})
      .then(
        (response) => {
          if (response.status === 200) {
            if (response.data.error) {
              return response.data.error;
            }
            return response;
          }
        },
        (err) => {
          if (err.response.status == 401) {
          }
          return err.response;
        }
      )
      .catch((reason) => {
        return reason;
      });
  }
}
