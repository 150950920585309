import React from "react";
import { Outlet } from "react-router-dom";

export const Layout = (props) => {
  return (
    <>
      <div className="video-background">
        <>
          <Outlet />
          {props.children && <div className="mt-3">{props.children}</div>}
        </>
      </div>
    </>
  );
};
