import React from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left-circle.svg";
import arrowRight from "../../assets/arrow-right-circle.svg";
import circleBg from "../../assets/circle-bg.png";
import { RoutesName } from "../../utils/RoutesName";
import { ButtonWig } from "../../widgets/ButtonWig";

export const OnboardingThree = () => {
  const navigate = useNavigate();

  return (
    <div className="main-bg" style={{ overflowX: "hidden" }}>
      <div style={{ position: "relative" }}>
        <img alt="" className="ob-three-circle" src={circleBg} />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ padding: "25px 0px" }}
        >
          <div className="d-flex flex-row align-items-center ob-three-right-chat-container">
            <div className="chat-right ob-three-chat-right">
              <p className="m-0 p-0">
                I'm in the mood for some drama tonight. Any recommendations?
              </p>
            </div>
            <div className="ms-3">
              <img src={arrowLeft} alt="" className="mb-1" />
              <p className="m-0 p-0" style={{ fontSize: "10px" }}>
                15:01
              </p>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center ob-three-chat-left-container">
            <div className="me-3">
              <img src={arrowRight} alt="" className="mb-1" />
              <p className="m-0 p-0" style={{ fontSize: "10px" }}>
                15:01
              </p>
            </div>
            <div className="left-chat ob-three-chat-left">
              <p className="m-0 p-0">
                If you're feeling dramatic, I recommend checking out The Whale.
                Brandan Fraser plays the lead role, and he's absolutely
                phenomenal.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="custom">
        <p
          className="personal-ai p-0 m-0 mt-3 position-relative"
          style={{ top: "223px" }}
        >
          <span>Fast & Accurate</span> <br />
          Responses
        </p>
        <ButtonWig
          className="mt-4 text-white fw-bold continue-btn continue-btn-ob-three"
          text={"Continue"}
          onClick={() => navigate(RoutesName.register)}
        />
        <div className="d-flex flex-row align-self-center mt-3 justify-content-center">
          <div className="ob-dot"></div>
          <div className="ob-dot"></div>
          <div className="ob-dot-max"></div>
        </div>
      </div>
    </div>
  );
};
