import React, { useEffect, useState } from 'react'
import Bot from '../assets/bot.png'
export const LeftChatLoading = ({scrollBottom}) => {
    
    const [i, setI] = useState(0);
    useEffect(() => {

        setTimeout(() => {
          setI(1)
    
        }, 200);
    
        setTimeout(() => {
          scrollBottom()
        }, 200);
    
    
      }, [])
    
    return (
        <div className='d-flex flex-column message' style={{ opacity: i }}>
        <div className="ms-4 mb-2 d-flex left-message p-3">
            <div className="wave">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
          </div>
        </div>
        <img src={Bot} alt="" width={22} height={17} />
        </div>
    )
}